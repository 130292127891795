import API from "../../API";
import axios from "axios";
import { ChallengesBrief } from "../../types/ChallengesBrief";
import { ChallengeDetailed } from "../../types/ChallengesDetailed";
import BooleanAnswer from "../../types/BooleanAnswer";
import { Runtimes } from "../../types/Runtimes";
import { CodeRunRequest } from "../../types/CodeRunRequest";
import { CodeRunDetails } from "../../types/CodeRunDetails";
import { Template } from "../../types/Template";
import { ValidatorJsonSchema } from "../../types/ValidatorJsonSchema";
import { ValidatorsCustomCode } from "../../types/ValidatorsCustomCode";
import { CodeRunHead, CodeRunHeads } from "../../types/CodeRunHead";
import { SLA } from "../../types/SLA";
import { CHALLENGES_PER_PAGE } from "../../Const";
import { axiosTokened } from "../../utils/AxiosInstances";
import { Solving, Solvings } from "../../types/Solvings";
import { Counter } from "../../types/counter";

//  |    exported with explicit export {allStuff}
//  |
//  V

interface OnlyPageParam {
  pageParam?: number;
}

const getChallengesBrief = async (
  page: OnlyPageParam,
  isReserved?: null | boolean,
  creatorUserId?: string,
  solverUserId?: string,
  templateId?: string,
  isSolved?: null | boolean,
  isAutorun?: null | boolean,
  isCreationSlaOk?: null | boolean,
  isGrabbable?: null | boolean,
  isRejected?: null | boolean,
  isReviewed?: null | boolean
) => {
  const emptyStrToUndefined = (obj: any) => (obj === "" ? undefined : obj);

  // for param to be undefined, value needs to be null or undefined
  var params: any = {
    limit: CHALLENGES_PER_PAGE,
    skip: CHALLENGES_PER_PAGE * (page.pageParam ?? 0),
    creator_user_id: emptyStrToUndefined(creatorUserId),
    solver_user_id: emptyStrToUndefined(solverUserId),
    template_id: emptyStrToUndefined(templateId),

    is_solved: isSolved,
    is_autorun_on: isAutorun,
    is_creation_sla_ok: isCreationSlaOk,
    is_up_for_grab: isGrabbable,
    is_rejected: isRejected,
    is_reviewed: isReviewed,
  };

  if (isReserved === false) {
    params["reservation_statuses"] = "free";
    params["is_up_for_grab"] = "true";
  }

  const { data } = await axios.get<ChallengesBrief>(API.getChallengesBrief, {
    params: params,
  });
  return data;
};

const getChallengeDetails = async (challengeId: number | string) => {
  const { data } = await axiosTokened.get<ChallengeDetailed>(
    API.genGetChallengeDetailed(challengeId)
  );
  return data;
};

interface ReserveParams {
  challengeId?: string;
  userToken: string;
}

const patchChallengeReserve = async (params: ReserveParams) => {
  await axios.patch(
    API.genPatchChallengeReserve(params.challengeId ?? ""),
    undefined,
    { headers: API.genHeadersUserToken(params.userToken) }
  );
};

const getIsChallengeReservedByUser = async (
  challengeId?: string,
  userId?: string
) => {
  const { data } = await axios.get<BooleanAnswer>(
    API.genGetIsChallengeReservedByUser(challengeId ?? "", userId ?? "")
  );
  return data;
};

const getRuntimes = async () => {
  const { data } = await axios.get<Runtimes>(API.getRuntimes);
  return data;
};

interface PostCodeParams {
  challengeId: string;
  codeRunRequest: CodeRunRequest;
}

const postCode = async (params: PostCodeParams) => {
  const { data } = await axiosTokened.post<CodeRunDetails>(
    API.genPostCode(params.challengeId),
    params.codeRunRequest
  );
  return data;
};

const getCodeRunDetails = async (
  challengeId?: string,
  codeRunNumber?: string,
  userId?: string
) => {
  const { data } = await axiosTokened.get<CodeRunDetails>(
    API.genGetCodeRunDetails(
      challengeId ?? "",
      codeRunNumber ?? "",
      userId ?? ""
    )
  );
  return data;
};

const getCodeRunHeadsByChallengeUser = async (
  challengeId?: string,
  userId?: string
) => {
  const { data } = await axiosTokened.get<CodeRunHeads>(
    API.genGetCodeRunHeadsByChallengeUser(challengeId ?? "", userId ?? "")
  );
  return data;
};

const getIsChallengeHasCodeByUser = async (
  challengeId?: string,
  userId?: string
) => {
  const { data } = await axios.get<BooleanAnswer>(
    API.genGetIsChallengeHasCodeByUser(challengeId ?? "", userId ?? "")
  );
  return data;
};

const patchEnableAutorun = async (challengeId?: string, userId?: string) => {
  await axiosTokened.patch(
    API.genPatchAutorunCodeRun(challengeId ?? "", userId ?? "")
  );
};

const getTemplate = async (templateId: string) => {
  const { data } = await axiosTokened.get<Template>(
    API.genGetTemplate(templateId)
  );
  return data;
};

const patchTemplateName = async (templateId: string, templateName: string) => {
  await axiosTokened.patch(API.genPatchTemplateName(templateId), {
    name: templateName,
  });
};

const patchChallengeDescription = async (
  challengeId: string,
  challengeDescription: string
) => {
  await axiosTokened.patch(API.genPatchChallengeDetails(challengeId), {
    description: challengeDescription,
  });
};

const patchChallengeName = async (
  challengeId: string,
  challengeName: string
) => {
  await axiosTokened.patch(API.genPatchChallengeDetails(challengeId), {
    name: challengeName,
  });
};

const patchChallengeBudget = async (
  challengeId: string,
  challengeBudget: any
) => {
  await axiosTokened.patch(API.genPatchChallengeDetails(challengeId), {
    budget: parseInt(challengeBudget),
  });
};

const postChallengeSendEmail = async (
  challengeId: string,
  emailTo: string,
  emailText: string
) => {
  await axiosTokened.post(API.getChallengeSendEmail(challengeId), {
    mail_to: emailTo,
    mail_text: emailText,
  });
};

export const getAllUserSubscriptions = async () => {
  const { data } = await axiosTokened.get(API.getAllUserSubscriptions);
  return data;
};

export const getAllProjectChallengesCount = async (projectId: string) => {
  const { data } = await axiosTokened.get<Counter>(API.genGetAllProjectChallengesCount(projectId));
  return data;
};

export const getFreeProjectChallengesCount = async (projectId: string) => {
  const { data } = await axiosTokened.get<Counter>(API.genGetFreeProjectChallengesCount(projectId));
  return data;
};

export const getSolverProjectChallengesCount = async (projectId: string) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetSolverProjectChallengesCount(projectId)
  );
  return data;
};

export const getProjectChallengesWithErrorsCount = async (projectId: string, showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetProjectChallengesWithErrorsCount(projectId, showMySolvings)
  );
  return data;
};

export const getProjectChallengesWithWarningsCount = async (projectId: string, showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetProjectChallengesWithWarningsCount(projectId, showMySolvings)
  );
  return data;
};

export const getProjectChallengesNeedFixCount = async (projectId: string, showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetProjectChallengesNeedFixCount(projectId, showMySolvings)
  );
  return data;
};

export const getProjectChallengesWaitingForReviewCount = async (projectId: string, showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetProjectChallengesWaitingForReviewCount(projectId, showMySolvings)
  );
  return data;
};

export const getProjectChallengesCreationSLABreachedCount = async (projectId: string, showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetProjectChallengesCreationSLABreachedCount(projectId, showMySolvings)
  );
  return data;
};

export const getProjectChallengesDeliverySLABreachedCount = async (projectId: string, showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetProjectChallengesDeliverySLABreachedCount(projectId, showMySolvings)
  );
  return data;
};

export const getAllChallengesCount = async () => {
  const { data } = await axiosTokened.get<Counter>(API.getAllChallengesCount);
  return data;
};

export const getFreeChallengesCount = async () => {
  const { data } = await axiosTokened.get<Counter>(API.getFreeChallengesCount);
  return data;
};

export const getSolverChallengesCount = async () => {
  const { data } = await axiosTokened.get<Counter>(API.getSolverChallengesCount);
  return data;
};

export const getChallengesWithErrorsCount = async (showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetChallengesWithErrorsCount(showMySolvings)
  );
  return data;
};

export const getChallengesWithWarningsCount = async (showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetChallengesWithWarningsCount(showMySolvings)
  );
  return data;
};

export const getChallengesNeedFixCount = async (showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetChallengesNeedFixCount(showMySolvings)
  );
  return data;
};

export const getChallengesWaitingForReviewCount = async (showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetChallengesWaitingForReviewCount(showMySolvings)
  );
  return data;
};

export const getChallengesCreationSLABreachedCount = async (showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetChallengesCreationSLABreachedCount(showMySolvings)
  );
  return data;
};

export const getChallengesDeliverySLABreachedCount = async (showMySolvings: boolean) => {
  const { data } = await axiosTokened.get<Counter>(
    API.genGetChallengesDeliverySLABreachedCount(showMySolvings)
  );
  return data;
};

const postChallengeSendEmailv2 = async (
  challengeId: string,
  emailTo: string[],
  emailText: string
) => {
  await axiosTokened.post(API.getChallengeSendEmailv2(challengeId), {
    mail_to: emailTo,
    mail_text: emailText,
  });
};

const postSendInvitationEmailToProject = async (
  projectId: string,
  emailTo: string
) => {
  await axiosTokened.post(API.genSendInvitationToProject(projectId), {
    mail_to: emailTo,
  });
}

export const updateUserStatus = async (userId: string, status: string) => {
  await axiosTokened.put(API.genUpdateUserStatus(userId, status), {});
};

export const getCurrentUser = async () => {
  const { data } = await axiosTokened.get(API.genGetCurrentUser(), {});
  return data;
};

export const getUser = async (userId: string) => {
  const { data } = await axiosTokened.get(API.genGetUser(userId), {});
  return data;
};

const patchChallengeTargetURL = async (
  challengeId: string,
  targetURL: string
) => {
  await axiosTokened.patch(API.genPatchChallengeDetails(challengeId), {
    target_url: targetURL,
  });
};

const getTemplateChallenges = async (templateId: string) => {
  const { data } = await axiosTokened.get<ChallengesBrief>(
    API.genGetTemplateChallenges(templateId)
  );
  return data;
};

const patchRecreateAutorun = async (challengeId?: string, userId?: string) => {
  await axiosTokened.patch(
    API.genPatchAutorunRecreate(challengeId ?? "", userId ?? "")
  );
};

// end of explicit export

export const getJsonSchemaValidator = async (templateId: string) => {
  const { data } = await axiosTokened.get<ValidatorJsonSchema>(
    API.genGetJsonSchemaValidator(templateId)
  );
  return data;
};

export const patchChallengeRunTime = async (
  challengeId: string,
  seconds: string
) => {
  await axiosTokened.patch(API.genPatchChallengeRunTime(challengeId), {
    seconds: seconds,
  });
};

export const patchJsonSchemaValidator = async (
  validatorId: string,
  jsonSchemaStr: string,
  customName?: string
) => {
  await axiosTokened.patch(API.genPatchJsonSchemaValidator(validatorId), {
    validator_custom_name: customName,
    json_schema_str: jsonSchemaStr,
  });
};

export const getCustomCodeValidators = async (templateId: string) => {
  const { data } = await axiosTokened.get<ValidatorsCustomCode>(
    API.genGetCustomCodeValidators(templateId)
  );
  return data;
};

export const postCustomCodeValidator = async (
  templateId: string,
  runtimeId: string,
  code: string,
  customName?: string
) => {
  await axiosTokened.post(API.genPostCustomCodeValidator(templateId), {
    validator_custom_name: customName,
    runtime_id: runtimeId,
    code: code,
  });
};

export const patchCustomCodeValidator = async (
  validatorId: string,
  runtimeId: string,
  code: string,
  customName?: string
) => {
  await axiosTokened.patch(API.genPatchCustomCodeValidator(validatorId), {
    validator_custom_name: customName,
    runtime_id: runtimeId,
    code: code,
  });
};

export const deleteValidatorInstance = async (validatorId: string) => {
  await axiosTokened.delete(API.deleteValidatorInstance, {
    data: { validator_id: validatorId },
  });
};

export const deleteChallenge = async (challengeId: string) => {
  await axiosTokened.delete(API.genDeleteChallenge(challengeId));
};

export const undeleteChallenge = async (challengeId: string) => {
  const { data } = await axiosTokened.patch(
    API.genPatchUndeleteChallenge(challengeId)
  );
  return data;
};

export const releaseChallenge = async (challengeId: string) => {
  await axiosTokened.get(API.genReleaseChallenge(challengeId));
};

interface UpdateChallengeFrequencyParams {
  challengeId: string;
  frequency_name: string;
}

export const updateChallengeFrequency = async ({
  challengeId,
  frequency_name,
}: UpdateChallengeFrequencyParams) => {
  await axiosTokened.get(
    API.genUpdateChallengeFrequency(challengeId, frequency_name)
  );
};

interface UpdateChallengeProjectParams {
  challengeId: string;
  projectId: string;
}

export const updateChallengeProject = async ({
  challengeId,
  projectId,
}: UpdateChallengeProjectParams) => {
  await axiosTokened.patch(
    API.genUpdateChallengeProject(challengeId, projectId)
  );
};

export const getChallengeSLA = async (challengeId: string) => {
  const { data } = await axiosTokened.get<SLA>(
    API.genGetChallengeSLA(challengeId)
  );
  return data;
};

export const getChallengeSolvings = async (challengeId: string) => {
  const { data } = await axiosTokened.get<Solvings>(
    API.genGetChallengeSolvings(challengeId)
  );
  return data;
};

export const getCodeRunHead = async (
  challengeId: string,
  codeRunNumber: string,
  userId: string
) => {
  const { data } = await axiosTokened.get<CodeRunHead>(
    API.genGetCodeRunHeadByChallengeUserNumber(
      challengeId,
      userId,
      codeRunNumber
    )
  );
  return data;
};

export const getSolving = async (challengeId: string, userId: string) => {
  const { data } = await axiosTokened.get<Solving>(
    API.genGetSolving(challengeId, userId)
  );
  return data;
};

export {
  getChallengesBrief,
  getChallengeDetails,
  patchChallengeReserve,
  patchChallengeDescription,
  patchChallengeName,
  patchChallengeTargetURL,
  patchChallengeBudget,
  getIsChallengeReservedByUser,
  getRuntimes,
  postCode,
  getCodeRunDetails,
  getCodeRunHeadsByChallengeUser,
  getIsChallengeHasCodeByUser,
  patchEnableAutorun,
  getTemplate,
  patchTemplateName,
  getTemplateChallenges,
  patchRecreateAutorun,
  postChallengeSendEmail,
  postChallengeSendEmailv2,
  postSendInvitationEmailToProject,
};
export type { ReserveParams, PostCodeParams, UpdateChallengeFrequencyParams, UpdateChallengeProjectParams};