import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Button,
  Tooltip,
  Switch,
  FormControlLabel,
  Tab,
  Tabs,
  Box,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  Stack,
  Checkbox,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Link,
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { BackendError } from "../../../types/BackendError";
import ErrorAlert from "../../../components/ErrorAlert";
import { dashboardTheme } from "../../../misc/DashboardTheme";
import { User } from "../../../types/Users";
import { ValidationStatus } from "../../../Const";
import { useChallengesCount } from "../hooks/UseChallengesCount";
import { default as ChalCreationTTabID } from "../../../types/ChallengeCreationTabsEnum";
import DashboardTTabID from "../../../types/DashboardTabsEnum";
import {
  CustomTabPanel,
  a11yProps,
} from "../../ChallengeViewSteps/components/CustomTabPanel";
import { useQueryProjectUsers } from "../../../hooks/UseQueryProjectUsers";
import { ProjectUser } from "../../../types/ProjectUsers";
import AddUserToProjectModal from "../../../components/AddUserToProjectModal";
import UpdateUserProjectRoleModal from "./UpdateUserProjectRoleModal";
import DuplicateProjectModal from "./DuplicateProjectModal";
import ProjectRoleEnum from "../../../types/ProjectRoleEnum";
import {
  patchMakeProjectOnboardingTemplate,
  updateProjectSubscription,
} from "../LocalOperations";
import { Perm } from "../../../Const";
import { usePerms } from "../../../hooks/UsePerms";
import { useMutation, useQueryClient } from "react-query";
import { ProjectMakeTemplateAnswer } from "../../solutionSubmission/components/ProjectCollision";
import {
  useInvalidateProject,
  useInvalidateUserProjects,
} from "../../../hooks/useQueryProjects";
import { useQueryProjectSubscriptions } from "../hooks/useQueryProjectSubscriptions";
import { useQueryProjectDetails } from "../hooks/useQueryProjectDetails";
import MakeProjectOnboardingTemplateModal from "./MakeProjectOnboardingTemplateModal";
import { useUserState } from "../../../hooks/UserStateProvider";
import {
  EditableProjectName,
  EditableProjectDescription,
} from "./EditableProjectFeature";

interface DashboardItem {
  title: string;
  subtitle: string;
  value: number | null;
  borderColor: string;
  link: string;
  error?: BackendError | null;
  loading?: boolean;
}

interface DashboardViewProps {
  title: string;
  user: User;
  projectId?: string;
  isProject: boolean;
  isOnboardingProjectTemplate?: boolean;
}

const TAB_LABELS: Record<DashboardTTabID, string> = {
  [DashboardTTabID.Dashboard]: "Dashboard",
  [DashboardTTabID.Settings]: "Settings",
  [DashboardTTabID.Notifications]: "Notifications",
};

const DashboardTabs: React.FC<DashboardViewProps> = ({
  title,
  user,
  projectId,
  isProject,
  isOnboardingProjectTemplate,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(DashboardTTabID.Dashboard);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") as DashboardTTabID;
  const perms = usePerms();
  const isModerator = perms.has(Perm.DELETE_CHALLENGE);

  const TABS: DashboardTTabID[] = [
    DashboardTTabID.Dashboard,
    ...(isProject ? [DashboardTTabID.Settings] : []),
    ...(isModerator ? [DashboardTTabID.Notifications] : []),
  ];

  useEffect(() => {
    if (tab && TAB_LABELS[tab]) {
      setValue(tab);
    } else if (!tab) {
      setValue(DashboardTTabID.Dashboard);
      searchParams.set("tab", DashboardTTabID.Dashboard);
      navigate({ search: searchParams.toString() });
    } else {
      navigate("/not_found");
    }
  }, [searchParams, navigate, tab]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: DashboardTTabID
  ) => {
    if (Object.values(DashboardTTabID).includes(newValue)) {
      setValue(newValue);
      const params: Record<string, string> = { tab: newValue };
      navigate({
        search: `?${createSearchParams(params)}`,
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="dashboard tabs">
          {TABS.map((tabId) => (
            <Tab
              label={TAB_LABELS[tabId]}
              key={tabId}
              value={tabId}
              {...a11yProps(tabId)}
            />
          ))}
        </Tabs>
      </Box>
      {TABS.map((tab) => (
        <CustomTabPanel key={tab} value={value} id={tab}>
          {tab === DashboardTTabID.Dashboard && (
            <DashboardView
              title={title}
              user={user}
              projectId={projectId}
              isProject={isProject}
            />
          )}
          {tab === DashboardTTabID.Settings && (
            <SettingsTab
              projectId={projectId}
              current_user={user}
              isOnboardingProjectTemplate={isOnboardingProjectTemplate}
            />
          )}
          {tab === DashboardTTabID.Notifications && (
            <NotificationsTab projectId={projectId} />
          )}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

const DashboardCard: React.FC<{ item: DashboardItem }> = ({ item }) => (
  <Grid item xs={2.5} md={2.5}>
    <Link to={item.link} style={{ textDecoration: "none" }}>
      <Card sx={{ borderLeft: `10px solid ${item.borderColor}` }}>
        <CardContent>
          <Typography variant="subtitle2">{item.title}</Typography>
          <Typography variant="body2" color="textSecondary">
            {item.subtitle}
          </Typography>
          {item.loading ? (
            <CircularProgress size={24} />
          ) : item.error ? (
            <Typography variant="body2" color="error">
              <ErrorAlert value={item.error} />
            </Typography>
          ) : (
            <Typography variant="h4">{item.value}</Typography>
          )}
        </CardContent>
      </Card>
    </Link>
  </Grid>
);

const SettingsTab: React.FC<{
  projectId?: string;
  current_user: User;
  isOnboardingProjectTemplate?: boolean;
}> = ({ projectId, current_user, isOnboardingProjectTemplate }) => {
  const {
    data: projectUsers,
    isLoading,
    error,
  } = useQueryProjectUsers(projectId || "", {
    enabled: !!projectId,
  });

  const [selectedUser, setSelectedUser] = useState<ProjectUser | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    isUpdateUserProjectRoleModalOpen,
    setisUpdateUserProjectRoleModalOpen,
  ] = useState(false);
  const [
    isMakeProjectOnboardingTemplateModalOpen,
    setIsMakeProjectOnboardingTemplateModalOpen,
  ] = useState(false);
  const [isDuplicateAsModalOpen, setIsDuplicateAsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const isOwnerOfTheProject =
    Number(current_user.id) ===
    projectUsers?.find((user) => user.project_role === ProjectRoleEnum.Owner)
      ?.user_id;

  const perms = usePerms();

  const qProject = useQueryProjectDetails(projectId || "");

  const project = qProject.data;

  const invalidateUserProjects = useInvalidateUserProjects();
  const invalidateProject = useInvalidateProject(projectId || "");

  const setProjectAsOnboardingTemplate = useMutation<
    ProjectMakeTemplateAnswer,
    BackendError,
    { projectId: string | number; isForce: boolean }
  >(
    ({ projectId, isForce }) =>
      patchMakeProjectOnboardingTemplate({ projectId, isForce }),
    {
      onSuccess: (data) => {
        if (data.is_success) {
          invalidateProject();
          invalidateUserProjects();
          setSnackbarMessage(
            "Project was successfully set to onboarding template"
          );
          setSnackbarOpen(true);
        } else {
          setIsMakeProjectOnboardingTemplateModalOpen(true);
        }
      },
    }
  );

  if (isLoading) return <CircularProgress />;
  if (error) return <ErrorAlert value={error as BackendError} />;

  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: ProjectUser
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateRoleClick = () => {
    setisUpdateUserProjectRoleModalOpen(true);
    handleMenuClose();
  };

  const handleAddUserSuccess = () => {
    setSnackbarMessage("User(s) added to the project successfully!");
    setSnackbarOpen(true);
  };

  const handleDuplicateProjectSuccess = () => {
    setSnackbarMessage("Project was successfully duplicated!");
    setSnackbarOpen(true);
  };

  const handleUpdateUserProjectRoleSuccess = () => {
    setSnackbarMessage("User's role updated successfully!");
    setSnackbarOpen(true);
  };

  const handleMakeProjectOnboardingTemplateSuccess = () => {
    setSnackbarMessage("Project was successfully set to onboarding template");
    setSnackbarOpen(true);
  };

  const handleIsProjectOnboardingSwitch = () => {
    setProjectAsOnboardingTemplate.mutate({
      projectId: projectId || "",
      isForce: false,
    });
  };

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "project_role", headerName: "Role", width: 120 },
    {
      field: "action",
      headerName: "Action",
      width: 75,
      renderCell: (params: any) => (
        <IconButton
          aria-controls="actions-menu"
          aria-haspopup="true"
          sx={{ marginBottom: 1 }}
          onClick={(event) => handleActionClick(event, params.row)}
          disabled={
            !isOwnerOfTheProject ||
            params.row.project_role === ProjectRoleEnum.Owner
          }
        >
          ...
        </IconButton>
      ),
    },
  ];

  const rows = projectUsers?.map((user: ProjectUser) => ({
    id: user.user_id,
    user_id: user.user_id, // Is needed to update user role
    name: user.shown_name || "none",
    email: user.email,
    project_role: user.project_role,
  }));

  return (
    <Grid
      container
      padding={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Grid item xs={12} container justifyContent="center">
        <Typography variant="h4" gutterBottom>
          Project details
        </Typography>
      </Grid>
      <Paper
        elevation={0}
        sx={{ padding: 2, marginBottom: 3, border: "none", width: "100%" }}
      >
        <Grid item container xs={12} marginTop={2}>
          <Grid item container sx={{ marginBottom: 2 }}>
            <Grid item xs={2}>
              <Typography
                variant="h6"
                sx={{ textDecoration: "none", fontWeight: "normal" }}
                component="span"
                color="textSecondary"
              >
                Project name:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              {isOwnerOfTheProject ? (
                <EditableProjectName projectId={projectId || ""} />
              ) : (
                <Typography
                  variant="h6"
                  sx={{ textDecoration: "none", fontWeight: "normal" }}
                  component="span"
                >
                  {project?.name}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: 2 }}>
            <Grid item xs={2}>
              <Typography
                variant="h6"
                sx={{ textDecoration: "none", fontWeight: "normal" }}
                component="span"
                color="textSecondary"
              >
                Project description:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              {isOwnerOfTheProject ? (
                <EditableProjectDescription projectId={projectId || ""} />
              ) : (
                <Typography
                  variant="h6"
                  sx={{ textDecoration: "none", fontWeight: "normal" }}
                  component="span"
                >
                  {project?.description}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid item xs={4}>
        {isOwnerOfTheProject && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setIsDuplicateAsModalOpen(true)}
          >
            Duplicate as
          </Button>
        )}
      </Grid>
      <Grid item xs={4} container justifyContent="center">
        <Typography variant="h4" gutterBottom>
          Project users
        </Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
        {isOwnerOfTheProject && (
          <>
            <Tooltip title="Assign users to current project">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setIsModalOpen(true)}
              >
                Add user
              </Button>
            </Tooltip>
            {perms.has(Perm.BLUEPRINT_PROJECT) && (
              <FormControlLabel
                control={<Switch />}
                label="Project is onboarding"
                checked={isOnboardingProjectTemplate}
                disabled={isOnboardingProjectTemplate}
                onChange={handleIsProjectOnboardingSwitch}
                sx={{ ml: 2 }}
              />
            )}
          </>
        )}
        <AddUserToProjectModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          projectUsers={projectUsers || []}
          projectId={projectId || ""}
          onUserAssignToProjectSuccess={handleAddUserSuccess}
        />
        {isDuplicateAsModalOpen && project && (
          <DuplicateProjectModal
            open={isDuplicateAsModalOpen}
            onClose={() => setIsDuplicateAsModalOpen(false)}
            duplicateFromProject={project}
            projectUsers={projectUsers || []}
            onProjectDuplicateSuccess={handleDuplicateProjectSuccess}
          />
        )}
      </Grid>
      <Grid container width="50%" marginTop={2}>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ border: "none" }}>
            <DataGrid
              rows={rows || []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20]}
              disableRowSelectionOnClick
              autoHeight
            />
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleUpdateRoleClick}>
                Update user role
              </MenuItem>
              <MenuItem>Remove user from project</MenuItem>
            </Menu>
          </Paper>
        </Grid>
        {setProjectAsOnboardingTemplate.isError && (
          <ErrorAlert
            value={setProjectAsOnboardingTemplate.error as BackendError}
            sx={{ marginTop: 1 }}
          />
        )}
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={30000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {selectedUser && (
        <UpdateUserProjectRoleModal
          open={isUpdateUserProjectRoleModalOpen}
          onClose={() => setisUpdateUserProjectRoleModalOpen(false)}
          user={selectedUser}
          projectId={projectId || ""}
          onUserProjectRoleUpdateSuccess={handleUpdateUserProjectRoleSuccess}
        />
      )}
      {isMakeProjectOnboardingTemplateModalOpen && (
        <MakeProjectOnboardingTemplateModal
          open={isMakeProjectOnboardingTemplateModalOpen}
          onClose={() => setIsMakeProjectOnboardingTemplateModalOpen(false)}
          projectId={projectId || ""}
          onMakeProjectOnboardingTemplateSuccess={
            handleMakeProjectOnboardingTemplateSuccess
          }
        />
      )}
    </Grid>
  );
};

const NotificationsTab: React.FC<{ projectId?: string }> = ({ projectId }) => {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] =
    useState<boolean>(false);
  const queryClient = useQueryClient();
  const [user] = useUserState();

  const notificationMappings: { [key: string]: string } = {
    "Snapshot not delivered": "not_delivered",
    "Validation failed": "error",
  };

  const updateSubscriptionMutation = useMutation<
    void,
    BackendError,
    { notification: string; active: boolean }
  >(
    (formData) =>
      updateProjectSubscription(
        formData.notification,
        formData.active,
        projectId
      ),
    {
      onSuccess: () => {
        setIsSuccessSnackbarOpen(true);
        setSuccessMessage("Subscription updated successfully");
        queryClient.invalidateQueries(["projectSubscriptions", projectId]);
      },
    }
  );

  const {
    data: subscriptions,
    isLoading: areSubscriptionsLoading,
    error,
    refetch,
  } = useQueryProjectSubscriptions(projectId || "");

  useEffect(() => {
    if (user && projectId) {
      refetch();
    }
  }, [user, projectId, refetch]);

  const selectedNotifications =
    subscriptions?.map((subscription) => subscription.notification) || [];

  const handleNotificationSelection = (
    notification: string,
    selected: boolean
  ) => {
    const notificationValue = notificationMappings[notification];
    updateSubscriptionMutation.mutate({
      notification: notificationValue,
      active: selected,
    });
  };

  return (
    <Box>
      {areSubscriptionsLoading ? (
        <CircularProgress />
      ) : error ? (
        <ErrorAlert value={error} />
      ) : (
        <>
          <Typography
            variant="h5"
            component="div"
            display="flex"
            justifyContent="center"
            marginBottom={3}
          >
            Select which notifications you would like to receive within project
            level
          </Typography>
          {Object.keys(notificationMappings).map((notification) => (
            <Stack
              key={notification}
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <Checkbox
                checked={selectedNotifications.includes(
                  notificationMappings[notification]
                )}
                onChange={(e) =>
                  handleNotificationSelection(notification, e.target.checked)
                }
              />
              <Typography variant="body1">{notification}</Typography>
            </Stack>
          ))}
          <Box sx={{ m: 1, minWidth: 120 }}>
            <Snackbar
              open={isSuccessSnackbarOpen}
              autoHideDuration={3000}
              onClose={() => setIsSuccessSnackbarOpen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={() => setIsSuccessSnackbarOpen(false)}
                severity="success"
              >
                {successMessage}
              </Alert>
            </Snackbar>
          </Box>
          {updateSubscriptionMutation.isError && (
            <ErrorAlert value={updateSubscriptionMutation.error} />
          )}
        </>
      )}
    </Box>
  );
};

const DashboardView: React.FC<DashboardViewProps> = ({
  title,
  user,
  projectId,
  isProject,
}) => {
  const [showUserSolvings, setShowUserSolvings] = useState(false);
  const {
    qAllChallengesCount,
    qFreeChallengesCount,
    qSolverChallengesCount,
    qChallengesWithErrorsCount,
    qChallengesWithWarningsCount,
    qChallengesNeedFixCount,
    qChallengesWaitingForReviewCount,
    qChallengesCreationSLABreachedCount,
    qChallengesDeliverySLABreachedCount,
  } = useChallengesCount(
    user,
    isProject ? projectId : undefined,
    showUserSolvings
  );

  const handleToggleSwitch = () => {
    setShowUserSolvings(!showUserSolvings);
  };

  const enrichLink = (baseUrl: string, applySolverId: boolean = true) => {
    let enrichedUrl = "";

    // Add project_id to the url if it's a project dashboard
    if (projectId) {
      enrichedUrl += baseUrl.includes("?")
        ? `/projects/challenges${baseUrl}&project_id=${projectId}`
        : `/projects/challenges${baseUrl}?project_id=${projectId}`;
    }

    // Add solver_user_id to the url if 'showMySolvings' switch is enabled and if 'applySolverId' flag is true
    if (
      applySolverId &&
      showUserSolvings &&
      !enrichedUrl.includes(`solver_user_id=${user.id}`)
    ) {
      enrichedUrl += enrichedUrl.includes("?")
        ? `&solver_user_id=${user.id}`
        : `?solver_user_id=${user.id}`;
    }

    return enrichedUrl;
  };

  const data = [
    {
      header: "My tasks",
      items: [
        {
          title: "All challenges",
          subtitle: "My tasks archive",
          value: qAllChallengesCount.data?.counter ?? null,
          borderColor: dashboardTheme.palette.primary.main,
          link: enrichLink("/", false),
          error: qAllChallengesCount.error,
          loading: qAllChallengesCount.isLoading,
        },
        {
          title: "Up for grabs",
          subtitle: "New challenges available to take",
          value: qFreeChallengesCount.data?.counter ?? null,
          borderColor: dashboardTheme.palette.success.main,
          link: enrichLink("/?is_up_for_grab=true", false),
          error: qFreeChallengesCount.error,
          loading: qFreeChallengesCount.isLoading,
        },
        {
          title: "Reserved",
          subtitle: "My tasks archive",
          value: qSolverChallengesCount.data?.counter ?? null,
          borderColor: dashboardTheme.palette.success.main,
          link: enrichLink(`/?solver_user_id=${user.id}`, false),
          error: qSolverChallengesCount.error,
          loading: qSolverChallengesCount.isLoading,
        },
      ],
    },
    {
      header: "Actions you need to take",
      items: [
        {
          title: "Failing",
          subtitle: "Code contains errors",
          value: qChallengesWithErrorsCount.data?.counter ?? null,
          borderColor: dashboardTheme.palette.error.main,
          link: enrichLink(
            `/?validation_statuses=${ValidationStatus.DATA_FAILURE}`
          ),
          error: qChallengesWithErrorsCount.error,
          loading: qChallengesWithErrorsCount.isLoading,
        },
        {
          title: "Warnings",
          subtitle: "Alerts for issues",
          value: qChallengesWithWarningsCount.data?.counter ?? null,
          borderColor: dashboardTheme.palette.warning.main,
          link: enrichLink(
            `/?validation_statuses=${ValidationStatus.SUCCESS_WITH_WARNING}`
          ),
          error: qChallengesWithWarningsCount.error,
          loading: qChallengesWithWarningsCount.isLoading,
        },
        {
          title: "Need fix",
          subtitle: "Tasks requiring corrections",
          value: qChallengesNeedFixCount.data?.counter ?? null,
          borderColor: dashboardTheme.palette.error.main,
          link: enrichLink(`/?is_rejected=true`),
          error: qChallengesNeedFixCount.error,
          loading: qChallengesNeedFixCount.isLoading,
        },
        {
          title: "Need review",
          subtitle: "Awaiting evaluation",
          value: qChallengesWaitingForReviewCount.data?.counter ?? null,
          borderColor: dashboardTheme.palette.primary.main,
          link: enrichLink(`/?is_reviewed=false`),
          error: qChallengesWaitingForReviewCount.error,
          loading: qChallengesWaitingForReviewCount.isLoading,
        },
      ],
    },
    {
      header: "SLA breached",
      items: [
        {
          title: "Creation SLA breached",
          subtitle: "Deadline breached",
          value: qChallengesCreationSLABreachedCount.data?.counter ?? null,
          borderColor: dashboardTheme.palette.primary.main,
          link: enrichLink(`/?is_creation_sla_ok=false`),
          error: qChallengesCreationSLABreachedCount.error,
          loading: qChallengesCreationSLABreachedCount.isLoading,
        },
        {
          title: "Delivery SLA breached",
          subtitle: "Deadline breached",
          value: qChallengesDeliverySLABreachedCount.data?.counter ?? null,
          borderColor: dashboardTheme.palette.primary.main,
          link: enrichLink(`/?is_delivery_sla_ok=false`),
          error: qChallengesDeliverySLABreachedCount.error,
          loading: qChallengesDeliverySLABreachedCount.isLoading,
        },
      ],
    },
  ];

  return (
    <Grid container paddingLeft={5} spacing={2}>
      <Grid container alignItems="center">
        <Grid item xs={4} />
        <Grid item xs={4} container justifyContent="center">
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          {isProject && (
            <Tooltip title="Create a new challenge for current project">
              <Link
                to={`/challenges/new/?tab=${ChalCreationTTabID.ChallengeCreationIntro}`}
                state={{ projectId: Number(projectId) }}
              >
                <Button variant="contained" color="primary" size="large">
                  New challenge
                </Button>
              </Link>
            </Tooltip>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={showUserSolvings}
                onChange={handleToggleSwitch}
              />
            }
            label="My solvings"
            sx={{ ml: 2 }}
          />
        </Grid>
      </Grid>
      {data.map((section, sectionIndex) => (
        <Grid container spacing={2} key={sectionIndex}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom style={{ marginTop: "20px" }}>
              {section.header}
            </Typography>
          </Grid>
          {section.items.map((item, itemIndex) => (
            <DashboardCard key={itemIndex} item={item} />
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardTabs;
