import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

import NavMenu from "./NavMenu";
import { Perm } from "./Const";
import { useUserState } from "./hooks/UserStateProvider";
import { usePerms } from "./hooks/UsePerms";
import ChangeUser from "./components/ChangeUser";
import { default as AccountCreationTab } from "./types/AccountCreationTabsEnum";
import { UserProjectsProvider } from "./hooks/UserProjectListProvider";
import { User } from "./types/Users";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled((props: AppBarProps) => <MuiAppBar {...props} />)(
  ({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

interface DrawerProps extends MuiDrawerProps {
  open?: boolean;
}

const Drawer = styled((props: DrawerProps) => <MuiDrawer {...props} />)(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

interface ClippedDrawerProps {
  children?: React.ReactNode;
}

const LINK_STYLE = {
  color: "white",
  textDecoration: "none",
  marginLeft: "20px",
};

function ResponsiveAppBar({
  onToggle,
  isExpanded,
  isLoggedIn,
  user,
}: {
  onToggle: () => void;
  isExpanded: boolean;
  isLoggedIn: boolean;
  user: User;
}) {
  const perms = usePerms();

  return (
    <AppBar position="fixed" open={isExpanded && isLoggedIn}>
      <Toolbar>
        {isLoggedIn && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onToggle}
            edge="start"
          >
            {isExpanded ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        )}
        <Link to="/" style={LINK_STYLE}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "yellow",
              textDecoration: "none",
            }}
          >
            Dataplatform
          </Typography>
        </Link>
        <Box sx={{ flexGrow: 1 }}>
          <Link
            to={`/users/new/?tab=${AccountCreationTab.SelectRole}`}
            style={LINK_STYLE}
          >
            Create account
          </Link>
          <a href="http://blog.dataplatform.lt" style={LINK_STYLE}>
            Knowledge hub
          </a>
        </Box>
        <Box>
          {perms.has(Perm.IMPERSONATE) ? (
            <ChangeUser />
          ) : (
            <strong style={{ marginLeft: "5px" }}>
              #{user.id} {user.email}
            </strong>
          )}
          <Link to="/login" style={LINK_STYLE}>
            Login
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

function BaseNavDrawer({ isExpanded }: { isExpanded: boolean }) {
  return (
    <Drawer
      variant="permanent"
      open={isExpanded}
      sx={{
        width: isExpanded ? drawerWidth : 75,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: isExpanded ? drawerWidth : 75,
          boxSizing: "border-box",
          overflowX: "hidden",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <UserProjectsProvider>
          <NavMenu isExpanded={isExpanded} />
        </UserProjectsProvider>
        <Divider />
      </Box>
    </Drawer>
  );
}

export default function BaseNav({ children }: ClippedDrawerProps) {
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [user] = useUserState();
  const isLoggedIn = user.email !== "unknown";

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "whitesmoke" }}>
      <CssBaseline />
      <ResponsiveAppBar
        onToggle={handleToggle}
        isExpanded={isExpanded}
        isLoggedIn={isLoggedIn}
        user={user}
      />
      {isLoggedIn && <BaseNavDrawer isExpanded={isExpanded} />}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
